export const bankList = [
  { name: "Access Bank", id: "044" },
  { name: "Access Bank(Diamond)", id: "063" },
  { name: "Ecobank Nigeria", id: "050" },
  { name: "Enterprise Bank", id: "084" },
  { name: "Fidelity Bank", id: "070" },
  { name: "First Bank of Nigeria", id: "011" },
  { name: "First City Monument Bank", id: "214" },
  { name: "Guaranty Trust Bank", id: "058" },
  { name: "Heritage Bank", id: "030" },
  { name: "Jaiz Bank", id: "301" },
  { name: "Keystone Bank", id: "082" },
  { name: "Mainstreet Bank", id: "014" },
  { name: "Polaris Bank", id: "076" },
  { name: "Providus Bank", id: "101" },
  { name: "Stanbic IBTC", id: "039" },
  { name: "Sterling Bank", id: "232" },
  { name: "Union Bank Nigeria", id: "032" },
  { name: "United Bank for Africa", id: "033" },
  { name: "Unity Bank", id: "215" },
  { name: "WEMA Bank", id: "035" },
  { name: "Zenith Bank", id: "057" },
];
